import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import firebase from 'firebase';
import {ProgressBar} from 'react-bootstrap';
import config from './firebase-config.json';
import useLocalStorage from "./useLocalStorage";
import useWindowSize from "./useWindowSize";

firebase.initializeApp(config);
const db = firebase.firestore();
const functions = firebase.functions();

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
    background-color: #e3e4e3;
    
    width: 80%;
    margin: 0 auto;
    @media only screen and (min-width: 1200px) {
        width: 1200px;
    }
`;

const GameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const GameAreaSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`

const Game = styled.div`

    width: 100vw;
    height: 56.25vw;

    background-color: lightgray;
    @media only screen and (min-width: 1200px) {
        height: 675px;
        width: 1200px;
    }
`;

const BottomBoxes = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 25px;
`;

const SocialItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const WhiteWrapper = styled.div`
  text-align: left;
  background-color: white;
  padding: 10px 32px;
  max-width: 672px;
  line-height: 1.7;
`

const WhiteWrapperCentered = styled(WhiteWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AzAffiliates = styled.div`
    flex: 1;
`;

const PpBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PButtons = styled.div`
    width: 80%;
    height: 80%;
`;

const Poll = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0px;
`;

const QBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px;
    width: 100%;
    @media only screen and (min-width: 700px) {
        width: auto;
    }
`;

const Header = styled.div`
    background-color: #f4f5f4;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 14px;
`;

const Body = styled.div`
    padding: 0px;
    display: flex;
    justify-content: center;
    background-color: white;
`;

const InnerBody = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Line = styled.div`
    display: flex;
    align-Items: center;
    & p {
        margin-bottom: 2px;
        margin-top: 2px;
    }
`;

const Radio = styled.input`
    margin-right: 10px;
`;

const Divider = styled.div`
    backgroundColor: gray;
    height: 1px;
    width: 80%;
    marginTop: 8px;
    marginBottom: 8px;
`;

const ResultsLabel = styled.p`
    marginTop: 0;
    marginBottom: 10px;
    color: lightred;
`;

const VoteBtn = styled.button`
   
    box-sizing: content-box;
    background-color:rgb(91, 183, 91);
    background-image:linear-gradient(rgb(98, 196, 98), rgb(81, 163, 81));
    border-color:rgba(0, 0, 0, 0.25);
    border-radius:6px;
    border-style:solid;
    border-width:1px;
    border-image-outset:0px;
    border-image-repeat:stretch;
    border-image-slice:100%;
    border-image-source:none;
    border-image-width:1;
    box-shadow:rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    color:rgb(255, 255, 255);
    cursor:pointer;
    display:inline-block;
    filter:none;
    font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size:17.5px;
    height:20px;
    line-height:20px;
    margin-top: 20px;
    margin-bottom:0px;
    padding: 6px 11px;
    text-align:center;
    text-decoration-color:rgb(255, 255, 255);
    text-decoration-line:none;
    text-decoration-style:solid;
    text-shadow:rgba(0, 0, 0, 0.25) 0px -1px 0px;
    text-size-adjust:100%;
    vertical-align:middle;
`;

const getDefaultResults = () => {
  return { yes: 0, no: 0 };
}

function App() {
  let [answers, setAnswers] = useState([null, null]);
  const [storedAnswers, setStoredAnswers] = useLocalStorage('answers', [null, null]);
  const [results, setResults] = useState([getDefaultResults(), getDefaultResults()]);
  const windowSize = useWindowSize();

  useEffect(() => {
    db.collection('questions').get().then((dbResults) => {
      let newResults = [...results]
      dbResults.forEach(result => {
        const data = result.data();
        if (data && data.index >= 0 && data.index < results.length) {
          newResults[data.index] = data;
        }
      })
      setResults(newResults)
    });
  }, []);

  const choseRadio = (questionIndex, val) => {
    setAnswers((currAnswers) => {
      let newAnswers = [...currAnswers];
      newAnswers[questionIndex] = val;
      return newAnswers;
    })
  }

  const vote = (questionIndex) => {
    let answer = answers[questionIndex];
    const voteFirebase = functions.httpsCallable('vote');
    voteFirebase({ answer: answer ? answer.toLowerCase() : '', questionIndex });
    let newStoredAnswers = [...storedAnswers];
    newStoredAnswers[questionIndex] = answer;
    setStoredAnswers(newStoredAnswers);

    let newResults = [...results];
    if (newResults[questionIndex] && newResults[questionIndex][answer.toLowerCase()] >= 0) {
      newResults[questionIndex] = {
        ...newResults[questionIndex],
        [answer.toLowerCase()]: newResults[questionIndex][answer.toLowerCase()] + 1
      };
      setResults(newResults);
    }
  }

  return (
    <>
      <GameWrapper>
        {windowSize.width >= 1200 ? (
          <GameAreaSide>

            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=creditcard10e-20&marketplace=amazon&region=US&placement=B07B46WWN2&asins=B07B46WWN2&linkId=638a3606f30a95deb46a8223a34c5a4d&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff">
            </iframe>

            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=creditcard10e-20&language=en_US&marketplace=amazon&region=US&placement=B0876M8N65&asins=B0876M8N65&linkId=ff98e38d077bd33d02ab3c0862b995a5&show_border=true&link_opens_in_new_window=true"></iframe>

          </GameAreaSide>
        ) : null}
        <Game>
          <iframe style={{ width: '100%', height: '100%' }} src={`https://secondstimulus.com/construct/`}></iframe>
        </Game>
        {windowSize.width >= 1200 ? (
          <GameAreaSide>
            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=creditcard10e-20&marketplace=amazon&region=US&placement=B0865JRFFM&asins=B0865JRFFM&linkId=7601bcad675c8b79b03e8ef429f932ef&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff">
            </iframe>
          </GameAreaSide>
        ) : null}
      </GameWrapper>
      <Screen>

        <BottomBoxes>
          <SocialItems>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <iframe
                src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2FSecondStimulus.com&layout=button&size=large&appId=227886861858076&width=77&height=28"
                width="77" height="28" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0"
                allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
            <a className="twitter-share-button"
               href="https://twitter.com/intent/tweet"
               data-size="large"
               data-url="https://secondstimulus.com/"
               data-hashtags="SecondStimulus">
              Tweet
            </a>
          </SocialItems>
        </BottomBoxes>

        {windowSize.width < 1200 ? (
          <BottomBoxes>

            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=creditcard10e-20&marketplace=amazon&region=US&placement=B07B46WWN2&asins=B07B46WWN2&linkId=638a3606f30a95deb46a8223a34c5a4d&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff">
            </iframe>

            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=creditcard10e-20&language=en_US&marketplace=amazon&region=US&placement=B0876M8N65&asins=B0876M8N65&linkId=ff98e38d077bd33d02ab3c0862b995a5&show_border=true&link_opens_in_new_window=true"></iframe>

            <iframe style={{ width: 120, height: 240 }} marginWidth="0" marginHeight="0" scrolling="no"
                    frameBorder="0"
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=creditcard10e-20&marketplace=amazon&region=US&placement=B0865JRFFM&asins=B0865JRFFM&linkId=7601bcad675c8b79b03e8ef429f932ef&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff">
            </iframe>
          </BottomBoxes>
        ) : null}

        <BottomBoxes>
          <WhiteWrapperCentered>
            <div style={{ maxWidth: 600 }}>
              We hope you enjoyed our game!
              <br /><br />
              This was built by furloughed workers as a volunteer project during this Pandemic. If you enjoyed our game,
              consider donating to help:
            </div>

            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick"/>
              <input type="hidden" name="hosted_button_id" value="CPGQGRX5UE6YA"/>
              <input style={{ height: 30 }} type="image"
                     src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit"
                     title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button"/>
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
            </form>

            <div>Part of each donation will be used to supply health care workers with PPE</div>
          </WhiteWrapperCentered>
        </BottomBoxes>

        <Poll>
          <QBox>
            <Header>
              <Title><strong>Question # 1</strong> Did you get your stimulus check yet?</Title>
            </Header>
            <Body>
            <InnerBody>
              {
                !storedAnswers[0] ? (
                  <>
                    <Line>
                      <Radio type='radio' checked={(answers[0] == 'Yes')} onChange={() => choseRadio(0, 'Yes')}></Radio>
                      <p>Yes</p>
                    </Line>
                    <Line>
                      <Radio type='radio' checked={(answers[0] == 'No')} onChange={() => choseRadio(0, 'No')}></Radio>
                      <p>No</p>
                    </Line>
                    <VoteBtn disabled={answers[0] == null} type='click' onClick={() => vote(0)}>Submit</VoteBtn>
                  </>
                ) : (
                  <>
                    <Divider/>
                    <ResultsLabel>Results:</ResultsLabel>
                    <Divider/>
                    <div style={{ width: '100%' }}>
                      <p><strong>Yes</strong> ({results[0].yes})</p>
                      <div style={{ width: '100%', marginBottom: '20px' }}>
                        <ProgressBar now={((results[0].yes / (results[0].yes + results[0].no))) * 100}/>
                      </div>
                      <p><strong>No</strong> ({results[0].no})</p>
                      <div style={{ width: '100%', marginBottom: '20px' }}>
                        <ProgressBar variant="danger" now={((results[0].no / (results[0].yes + results[0].no))) * 100}/>
                      </div>
                    </div>
                  </>
                )
              }

            </InnerBody>
            </Body>
          </QBox>
          <QBox>
            <Header>
              <Title><strong>Question # 2</strong> Do you need a second stimulus?</Title>
            </Header>
            <Body>
            <InnerBody>
              {
                !storedAnswers[1] ? (
                  <>
                    <Line>
                      <Radio type='radio' checked={(answers[1] == "No")} onChange={() => choseRadio(1, "No")}></Radio>
                      <p>Nah, I'm super rich</p>
                    </Line>
                    <Line>
                      <Radio type='radio' checked={(answers[1] == 'Yes')} onChange={() => choseRadio(1, 'Yes')}></Radio>
                      <p>Show me the money</p>
                    </Line>

                    <VoteBtn disabled={answers[1] == null} type='click' onClick={() => vote(1)}>Submit</VoteBtn>
                  </>
                ) : (
                  <>
                    <Divider/>
                    <ResultsLabel>Results:</ResultsLabel>
                    <Divider/>
                    <div style={{ width: '100%' }}>
                      <p><strong>Nah, I'm super rich</strong> ({results[1].no})</p>
                      <div style={{ width: '100%', marginBottom: '20px' }}>
                        <ProgressBar now={(results[1].no / (results[1].yes + results[1].no)) * 100}/>
                      </div>
                      <p><strong>Show me the money</strong> ({results[1].yes})</p>
                      <div style={{ width: '100%', marginBottom: '20px' }}>
                        <ProgressBar variant="danger" now={(results[1].yes / (results[1].yes + results[1].no)) * 100}/>
                      </div>
                    </div>
                  </>
                )
              }

            </InnerBody>
            </Body>
          </QBox>
        </Poll>

        <BottomBoxes>
          <WhiteWrapper>
            Hey check out Fluz, it's a new app that gives you cash back when you pay with your 📲.<br/>
            I’ll VOUCH for you.👍🤛<br/>
            Join Fluz through my link and get 3 vouchers for up to 35% cash back at Chipotle, Domino’s, Spotify, and
            more.<br/>
            <a href="https://joinfluz.app.link/CREDITCARD101"
               target="_blank">https://joinfluz.app.link/CREDITCARD101</a>
          </WhiteWrapper>
        </BottomBoxes>
        <BottomBoxes>
          <a href={`https://amzn.to/2RINEOB`} target={'_blank'}>Buy Sanitizers on Amazon</a>
        </BottomBoxes>
      </Screen>
    </>
  );
}


export default App;
